.input1{  
    border-radius: 5px;
    margin-top:3px;
    width: 100%;
    border :1px solid #B8B8B8;
    background-color: #fff;
    height :20px;
}
.input2{
    height:20px;
    border-radius: 5px;
    margin-top:3px;
    width: 100%;
    border :1px solid #B8B8B8;
    background-color: #fff;
  }  

.inputcommonM{

  background: white;
  font-size: 18px;
  width:300px;
}

.inputcommon {
  border: none;
  background: white;
  font-size: 15px;
  width: 300px;
  padding : 10px 0px;
  
}
.inputNoFocus {
  border-bottom: 1px solid #ededed;

}


.inputCommon{
  padding: 10px;
  width: 260px;
  border: 1px solid #9e9b9b;
  border-radius: 5px
}
.inputInfoCommon{
  padding: 10px;
  width: 90%;
  border: 1px solid #9e9b9b;
  border-radius: 5px
}



.inputFocus {
  border: 1.2px solid #0303f1;
  transition: 0.1s;
}
.highlight {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
}

.highlight:focus-within {
  background-size: 100% 100%;

}
.underline1 {
  cursor: pointer;
  color: #7DD4E7;
 
  height:1px;
  /*2개 그라디언트 underline 만드는 방법*/
  background-image:
    linear-gradient(rgb(176, 251, 188), rgb(10, 250, 50));
  background-size: 100% 2px, 100% 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat, no-repeat;
  transition: background-size 10000ms linear;
}

.underline1:focus {
  background-size: 0 2px, 100% 2px;
}
.spread-underline {
  color: #333;
  text-decoration: none;
  display: inline-block;
  padding: 15px 0;
  position: relative;
}

.spread-underline:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #ffb000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.spread-underline:hover:after {
  width: 100%;
  left: 0;
}




/* .inputFoucs::after{
  content: "";
  display: block;
  position: absolute;

  border-bottom: 1px solid #0303f1;
  transition: 0.6s; 
}

.inputFocus {
    content: "";
    position: absolute;
    left: 0;
    width: 80%;
    height: 1.5px;
    margin: 2px 0 0;
    background-color: #0303f1;
} */
/* .inputFocus2 {
  content: "";
  position: absolute;
  left: 0;
  width: 30%;
  height: 1.5px;
  margin: 2px 0 0;
  background-color: #0303f1;
} */



.to-right-underline {
  position: relative;
}

.to-right-underline:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0px;
  height: 1px;
  margin: 5px 0 0;
  transition: all 0.5s ease-in-out;
  transition-duration: 1s;
  opacity: 1;
  background-color: #0303f1;
}

.to-right-underline:hover::after {
  width: 100%;
  opacity: 1;
}

.flexgrow06{ flex-grow :0.6;}
.flexgrow1{flex-grow :1;}
.flexgrow2{flex-grow :2;}
.flexgrow3{flex-grow :3;}
.flexgrow4{flex-grow :4;}
.flexgrow5{flex-grow :5;}
.flexgrow6{flex-grow :6;}
.Box{
  box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09)
}
.swiper-slide{
  display: flex;
}
.Map{
  width: 400px;
  height: 900px;
  position: relative;
}
.InputValue{
  border: none;
  background: white;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  width: 40%;
}
.css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-actionBar{
  display: none;
}

.swiper-scrollbar{
  display: none;
}


.Map{
  width: 100%;
  height: 100vh;
  overflow:inherit !important;
}

/* .customoverlay {position:relative;bottom:85px;border-radius:6px;border: 1px solid #ccc;border-bottom:2px solid #ddd;float:left;}
.customoverlay:nth-of-type(n) {border:0; box-shadow:0px 1px 2px #888;}
.customoverlay a {display:block;text-decoration:none;color:#000;text-align:center;border-radius:6px;font-size:14px;font-weight:bold;overflow:hidden;background: #d95050;background: #d95050 url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png) no-repeat right 14px center;}
.customoverlay .title {display:block;text-align:center;background:#fff;margin-right:35px;padding:10px 15px 5px;font-size:12px;font-weight:bold;}
.customoverlay .price {display:block;background:#fff;margin-right:35px;font-size:13px;text-align: left; padding-left:18px; color:red}
.customoverlay:after {content:'';position:absolute;margin-left:-12px;left:50%;bottom:-12px;width:22px;height:12px;background:url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')} */


.TrendingXScroll::-webkit-scrollbar{
  display: none;
}
.TrendingYScroll::-webkit-scrollbar{
    display: none;
}
table {
  border-collapse: collapse;
  border: 0;
}

th,
td {
  border: 1px solid #aaa;
  background-clip: padding-box;
  scroll-snap-align: start;
  text-align: center;
  min-width: 60px;

}


thead {
  position: relative;
}

th,
td {
  min-width: 3rem;
  text-align: center;
  margin: 0;
  min-height: 80px;
}

thead th {
  position: sticky;
  top: 0;
  background-clip: padding-box;
}

thead th.pin {
  left: 0;


}

tbody th {
  background-clip: padding-box;

}

tbody {

  position: relative;
}

tbody th {
  position: sticky;
  left: 0;
}

thead th,
tbody th {
  background-color: #f8f8f8;
}

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink-effect 1s step-end infinite;

  /*
  animation-name: blink-effect;
  animation-duration: 1s;
  animation-iteration-count:infinite;
  animation-timing-function:step-end;
  */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.button:hover {
  position: relative;
  top: 2px;
}
.button{
  cursor:pointer;
}

/* input[type=number] {
  -moz-appearance: textfield;
} */
